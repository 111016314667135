import { icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { CanvasRenderingContext2D } from 'canvas';

//----------------------------------------------------------------------------
// Make Elements From JSON

export function insertIcon(elem: HTMLElement, x: IconDefinition): void {
    Array.from(icon(x).node).map((n: Node): void => {elem.appendChild(n);});
}

export function removeChildren(node: Node): void {
    while (node.firstChild) {
        node.removeChild(node.firstChild);
    }
}

export function replaceIcon(elem: HTMLElement, x: IconDefinition): void {
    removeChildren(elem);
    insertIcon(elem, x);
}

interface NodeOpts {
    id?: string;
    for?: string;
    text?: string;
    icon?: IconDefinition;
    parent?: Node;
    children?: Node[];
    className?: string;
    style?: Partial<CSSStyleDeclaration>;
    attrib?: {[index: string]: string};
    title?: string;
    tabindex?: number;
    hidden?: boolean;
}

interface NodeInputOpts extends NodeOpts {
    disabled?: boolean;
}

export function mkNode(tag: 'img', opts?: NodeOpts): HTMLImageElement
export function mkNode(tag: 'div', opts?: NodeOpts): HTMLDivElement
export function mkNode(tag: 'text', opts?: NodeOpts): Text
export function mkNode(tag: 'icon', opts?: NodeOpts): HTMLSpanElement
export function mkNode(tag: 'textarea', opts?: NodeOpts): HTMLTextAreaElement
export function mkNode(tag: 'p', opts?: NodeOpts): HTMLParagraphElement
export function mkNode(tag: 'span', opts?: NodeOpts): HTMLSpanElement
export function mkNode(tag: 'button', opts?: NodeInputOpts): HTMLButtonElement
export function mkNode(tag: 'canvas', opts?: NodeOpts): HTMLCanvasElement
export function mkNode(tag: 'select', opts?: NodeOpts): HTMLSelectElement
export function mkNode(tag: 'option', opts?: NodeOpts): HTMLOptionElement
export function mkNode(tag: 'input', opts?: NodeInputOpts): HTMLInputElement
export function mkNode(tag: 'video', opts?: NodeOpts): HTMLVideoElement
export function mkNode(tag: 'audio', opts?: NodeOpts): HTMLAudioElement
export function mkNode(tag: 'form', opts?: NodeOpts): HTMLFormElement
export function mkNode(tag: 'table', opts?: NodeOpts): HTMLTableElement
export function mkNode(tag: 'tr', opts?: NodeOpts): HTMLTableRowElement
export function mkNode(tag: 'td', opts?: NodeOpts): HTMLTableDataCellElement
export function mkNode(tag: 'label', opts?: NodeOpts): HTMLLabelElement
export function mkNode(tag: 'h1', opts?:NodeOpts): HTMLHeadingElement
export function mkNode(tag: 'h2', opts?:NodeOpts): HTMLHeadingElement
export function mkNode(tag: 'h3', opts?:NodeOpts): HTMLHeadingElement
export function mkNode(tag: string, opts?: NodeInputOpts): Node {
    if (tag === 'text') {
        const elem = document.createTextNode(opts?.text ? opts.text : '') ;
        if (opts?.parent) {
            opts?.parent.appendChild(elem);
        }
        return elem;
    } else if (tag === 'icon') {
        const elem = document.createElement('span');
        if (opts) {
            if (opts.icon) {
                insertIcon(elem, opts.icon);
            }
            if (opts.parent) {
                opts.parent.appendChild(elem);
            }
            if (opts.className) {
                elem.className = opts.className;
            }
            if (opts.style) {
                for (const x in opts.style) {
                    elem.style[x] = opts.style[x] ?? '';
                }
            }
        }
        return elem;
    } else {
        const elem = document.createElement(tag);
        if (opts) {
            if (opts.id) {
                elem.id = opts.id;
            }
            if (opts.for) {
                elem.setAttribute('for', opts.for);
            }
            if (opts.className) {
                //elem.setAttribute('class', opts.className);
                elem.className = opts.className;
            }
            if (opts.style) {
                for (const x in opts.style) {
                    //(elem.style as unknown as {[index: string]: string})[x] = opts.style[x] as string;
                    elem.style[x] = opts.style[x] ?? '';
                }
            }
            if (opts.attrib) {
                for (const x in opts.attrib) {
                    elem.setAttribute(x, opts.attrib[x]);
                }
            }
            if (opts.children) {
                for (const child of opts.children) {
                    elem.appendChild(child);
                }
            }
            if (opts.parent) {
                opts.parent.appendChild(elem);
            }
            if (opts.title) {
                elem.title = opts.title;
            }
            if (opts.tabindex) {
                elem.tabIndex = opts.tabindex;
            }
            if (opts.hidden) {
                elem.hidden = opts.hidden;
            }
            if (opts.disabled && elem instanceof HTMLInputElement) {
                elem.disabled = opts.disabled;
            }
        }
        return elem;
    }

}

interface NodeSpec {
    elem: string;
    id?: string;
    for?: string;
    text?: string;
    icon?: IconDefinition;
    parent?: string;
    children?: NodeSpec[];
    className?: string;
    tip?: string;
    style?: Partial<CSSStyleDeclaration>;
    attrib?: {[index: string]: string};
}

function makeElement(dom: {[index: string]: Node}, def: NodeSpec): Node {
    if (def.elem === 'text') {
        const elem = document.createTextNode(def.text ? def.text : '') ;
        if (def.parent) {
            dom[def.parent].appendChild(elem);
        }
        return elem;
    } else if (def.elem === 'icon') {
        const elem = document.createElement('span');
        const x = def.icon;
        if (x) {
            Array.from(icon(x).node).map((n: Node): void => {elem.appendChild(n);});
        }
        if (def.className) {
            //elem.setAttribute('class', def.className);
            elem.className = def.className;
        }
        if (def.style) {
            for (const x in def.style) {
                (elem.style as unknown as {[index: string]: string})[x] = def.style[x] as string;
            }
        }
        if (def.parent) {
            dom[def.parent].appendChild(elem);
        }
        if (def.attrib) {
            for (const x in def.attrib) {
                elem.setAttribute(x, def.attrib[x]);
            }
        }
        return elem;
    } else {
        const elem: HTMLElement = document.createElement(def.elem);
        if (def.id) {
            elem.id = def.id;
        }
        if (def.for) {
            elem.setAttribute('for', def.for);
        }
        if (def.className) {
            //elem.setAttribute('class', def.className);
            elem.className = def.className;
        }
        if (def.tip) {
            elem.title = def.tip;
        }
        if (def.style) {
            for (const x in def.style) {
                (elem.style as unknown as {[index: string]: string})[x] = def.style[x] as string;
            }
        }
        if (def.attrib) {
            for (const x in def.attrib) {
                elem.setAttribute(x, def.attrib[x]);
            }
        }
        if (def.children) {
            for (let i = 0; i < def.children.length; ++i) {
                elem.appendChild(makeElement(dom, def.children[i]));
            }
        }
        if (def.parent) {
            dom[def.parent].appendChild(elem);
        }
        return elem;
    }
}

export type NodeSpecObject<T> = {
    [K in keyof T]: NodeSpec;
}

export function makeElements<T>(def: NodeSpecObject<T>): T {
    const dom: {[index: string]: Node} = {};
    for (const x in def) {
        dom[x] = makeElement(dom, def[x]);
    }
    return dom as unknown as T;
}

export function removeNode(node: Node): void {
    const parent = node.parentNode;
    if (parent) {
        parent.removeChild(node);
    }
}

export function fold<A, B>(i: Iterator<A>, acc: B, f: (x: B, y: A, j: number) => B): B {
    let j = 0;
    while (true) {
        const x = i.next();
        if (x.done) {
            return acc;
        }
        acc = f(acc, x.value, j++);
    }
}

export function pfold<A, B>(i: Iterator<A>, f: (x: Promise<B>, y: A, j: number) => Promise<B>): (v: B) => Promise<B> {
    return (v: B): Promise<B> => {
        let acc = Promise.resolve(v);
        let j = 0;
        while (true) {
            const x = i.next();
            if (x.done) {
                return acc;
            }
            acc = f(acc, x.value, j++);
        }
    }
}

export class RangeIterator implements Iterator<number> {
    private start: number;
    private end: number;

    public constructor(start: number, end: number) {
        this.start = start;
        this.end = end;
    }

    public next(): IteratorResult<number> {
        return (this.start < this.end) ?
            {value: this.start++, done: false} :
            {value: this.start++, done: true};
    }
}

export function wait(n: number): Promise<void> {
    return new Promise((succ): void => {
        setTimeout(succ, n);
    });
}

export function yieldMicroTask(): Promise<void> {
    return new Promise(succ => {succ();});
}

export function yieldMacroTask(): Promise<void> {
    return new Promise((succ): void => {
        setImmediate(succ);
    });
}

export function updateUrl(hash: string): Promise<void> {
    return new Promise((succ): void => {
        window.location.hash = hash;
        setImmediate(succ);
    });
}

const poly = 0xedb88320;
export function crc32(s: string, crc?: number): number {
    crc = (~((crc == null) ? 0 : crc)) >>> 0; // test for both undefined and null
    for (let i = 0; i < s.length; ++i) {
        crc ^= s.charCodeAt(i);
        for (let j = 15; j >= 0; --j) {
            crc = ((crc >>> 1) ^ ((-(crc & 1)) & poly)) >>> 0
        }
    }
    return (~crc) >>> 0;
}

const val32 = 0x100000000;
export class UInt64 {
    private high: number;
    private low: number;

    private static mul32u = (a: number, b: number): UInt64 => {
        const ah = a >>> 16, al = a & 0xffff
            , bh = b >>> 16, bl = b & 0xffff
            , ahbl = ah * bl, albh = al * bh
            , l = (al * bl) + ((ahbl << 16) >>> 0) + ((albh << 16) >>> 0)
            , h = (ah * bh) + (ahbl >>> 16) + (albh >>> 16) + (l / val32)
        return new UInt64(h, l);
    }

    public constructor(high: number, low: number) {
        this.high = high >>> 0;
        this.low = low >>> 0;
    }

    public copy(): UInt64 {
        return new UInt64(this.high, this.low);
    }

    public xor(x: UInt64): UInt64 {
        this.high ^= x.high >>> 0;
        this.low ^= x.low >>> 0;
        return this;
    }

    public add(x: UInt64): UInt64 {
        this.low += x.low;
        this.high = (this.high + x.high + (this.low / val32)) >>> 0;
        this.low >>>= 0;
        return this;
    }

    public mul(x: UInt64): UInt64 {
        const m = UInt64.mul32u(this.low, x.low)
            .add(UInt64.mul32u(this.low, x.high).shl(32))
            .add(UInt64.mul32u(this.high, x.low).shl(32));
        this.high = m.high;
        this.low = m.low;
        return this;
    }

    public shl(n: number): UInt64 {
        if (n < 32) {
            this.high = ((this.high << n) | (this.low >>> (32 - n))) >>> 0;
            this.low = (this.low << n) >>> 0;
        } else {
            this.high = (this.low << (n - 32)) >>> 0;
            this.low = 0;
        }
        return this;
    }

    public shr(n: number): UInt64 {
        if (n < 32) {
            this.low = ((this.low >>> n) | (this.high << (32 - n))) >>> 0;
            this.high = this.high >>> n;
        } else {
            this.low = this.high >>> (n - 32);
            this.high = 0;
        }
        return this;
    }

    public or(n: number): UInt64 {
        this.low |= n;
        return this;
    }

    public toNumber(): number {
        return this.low;
    }
}

interface PRNG {
    range(min: number, max: number): number;
}

const multiplier = new UInt64(0x5851f42d, 0x4c957f2d);
export class PCG32 implements PRNG {
    private state: UInt64;
    private inc: UInt64;

    public constructor(initstate: UInt64, initseq: UInt64) {
        this.state = new UInt64(0, 0);
        this.inc = initseq.copy().shl(1).or(1);
        this.next();
        this.state.add(initstate);
        this.next();
    }

    public next(): number {
        const oldstate = this.state.copy();
        this.state.mul(multiplier).add(this.inc.or(1));
        const xorshifted = oldstate.copy().shr(18).xor(oldstate).shr(27).toNumber();
        const rot = oldstate.shr(59).toNumber();
        return ((xorshifted >>> rot) | (xorshifted << ((-rot) & 31))) >>> 0;
    }

    public range(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        const d = max - min;
        const m = Math.floor(0xffffffff / d) * d;
        let r = this.next();
        while (r >= m) {
            r = this.next();
        }
        return (r % d) + min;
    }
}

function swap<A>(a: A[], x: number, y: number): void {
    if (x !== y) {
        const tmp = a[x];
        a[x] = a[y];
        a[y] = tmp;
    }
}

export function shuffle<A>(p: PRNG, a: A[]): void {
    const n = a.length;
    for (let i = 0; i < (n - 1); ++i) {
        const j = p.range(i, n);
        swap(a, i, j);
    }
}

const epsilon = 0.00000001;
export class Transform {
    public readonly s: number;
    public readonly r: number;
    public readonly tx: number;
    public readonly ty: number;

    public constructor(s: number, r: number, tx: number, ty: number) {
        this.s = s;
        this.r = r;
        this.tx = tx;
        this.ty = ty;
    }

    public static readonly identity = new Transform(1, 0, 0, 0);

    public use(context: CanvasRenderingContext2D): void {
        context.setTransform(this.s, this.r, -this.r, this.s, this.tx, this.ty);
    }

    public scaling(): number {
        return Math.sqrt(this.s * this.s + this.r * this.r);
    }

    public rotation(): number {
        return Math.atan2(this.r, this.s);
    }

    public translation(): [number, number] {
        return [this.tx, this.ty];
    }

    public apply({x, y, w = 1}:{x: number, y: number, w?: number}): {x: number, y: number} {
        return {
            x: x * this.s - y * this.r + w * this.tx,
            y: x * this.r + y * this.s + w * this.ty
        }
    }

    public multiplyBy(t: Transform): Transform {
        return new Transform(
            this.s * t.s - this.r * t.r,
            this.s * t.r + this.r * t.s,
            this.s * t.tx - this.r * t.ty + this.tx,
            this.r * t.tx + this.s * t.ty + this.ty
        );
    }

    public scaleBy(multiplier: number, [x, y] = [0, 0]): Transform {
        return new Transform(
            multiplier * this.s,
            multiplier * this.r,
            multiplier * this.tx + (1 - multiplier) * x,
            multiplier * this.ty + (1 - multiplier) * y
        );
    }

    public scaleXY(mx: number, my: number, {x, y} = {x: 0, y: 0}): Transform {
        return new Transform(
            mx * this.s,
            my * this.r,
            mx * this.tx + (1 - mx) * x,
            my * this.ty + (1 - my) * y
        )
    }

    public rotateBy(radians: number, [x, y] = [0, 0]): Transform {
        const co = Math.cos(radians);
        const si = Math.sin(radians);
        return new Transform(
            this.s * co - this.r * si,
            this.s * si + this.r * co,
            (this.tx - x) * co - (this.ty - y) * si + x,
            (this.tx - x) * si + (this.ty - y) * co + y
        );
    }

    public translateBy(dx: number, dy: number): Transform {
        return new Transform(this.s, this.r, this.tx + dx, this.ty + dy);
    }

    public inverse(): Transform {
        const g = this.s * this.s + this.r * this.r;
        if (Math.abs(g) < epsilon) {
            return new Transform(1, 0, 0, 0); // return identity if there is no inverse
        }
        return new Transform(
            this.s / g,
            -this.r / g,
            (-this.s * this.tx - this.r * this.ty) / g,
            (this.r * this.tx - this.s * this.ty) / g
        );
    }
}

export function estimateTranslation(x: [number, number][], y: [number, number][]): Transform {
    const n = Math.min(x.length, y.length);

    let a, b, c, d, a1 = 0, b1 = 0, c1 = 0, d1 = 0;

    for (let i = 0; i < n; ++i) {
        a = x[i][0];
        b = x[i][1];
        c = y[i][0];
        d = y[i][1];
        a1 += a;
        b1 += b;
        c1 += c;
        d1 += d;
    }

    if (n < 1) {
        return new Transform(1, 0, 0, 0);
    }

    const s = 1, r = 0, tx = (c1 - a1) / n, ty = (d1 - b1) / n;

    return new Transform(s, r, tx, ty);
}

export function estimateScaling(x: [number, number][], y: [number, number][], p: [number, number]): Transform {
    const n = Math.min(x.length, y.length),
        p1 = p[0],
        p2 = p[1];

    let a, b, c, d,
        ac = 0,
        bd = 0,
        aa = 0,
        bb = 0;

    for (let i = 0; i < n; ++i) {
        a = x[i][0] - p1;
        b = x[i][1] - p2;
        c = y[i][0] - p1;
        d = y[i][1] - p2;
        ac += a * c;
        bd += b * d;
        aa += a * a;
        bb += b * b;
    }

    const g = aa + bb;

    if (Math.abs(g) < epsilon) {
        return new Transform(1, 0, 0, 0);
    }

    const s = (ac + bd) / g,
        r = 0,
        tx = (1 - s) * p1,
        ty = (1 - s) * p2;

    return new Transform(s, r, tx, ty);
}

export function estimateRotation(x: [number, number][], y: [number, number][], p: [number, number]): Transform {
    const n = Math.min(x.length, y.length),
        p1 = p[0],
        p2 = p[1];

    let a, b, c, d,
        ac = 0,
        ad = 0,
        bc = 0,
        bd = 0;

    for (let i = 0; i < n; ++i) {
        a = x[i][0] - p1;
        b = x[i][1] - p2;
        c = y[i][0] - p1;
        d = y[i][1] - p2;
        ac += a * c;
        ad += a * d;
        bc += b * c;
        bd += b * d;
    }

    const v = ac + bd,
        w = ad - bc,
        g = Math.sqrt(v * v + w * w);

    if (Math.abs(g) < epsilon) {
        return new Transform(1, 0, 0, 0);
    }

    const s = v / g,
        r = w / g,
        tx = p1 - p1 * s + p2 * r,
        ty = p2 - p1 * r - p2 * s;

    return new Transform(s, r, tx, ty);
}

export function estimateTranslationScaling(x: [number, number][], y: [number, number][]): Transform {
    const n = Math.min(x.length, y.length);

    if (n === 0) {
        return new Transform(1, 0, 0, 0);
    }

    let a = x[0][0], b = x[0][1], c = y[0][0], d = y[0][1],
        a1 = a, b1 = b, c1 = c, d1 = d,
        a2 = a * a, b2 = b * b, ac = a * c, bd = b * d;

    for (let i = 1; i < n; ++i) {
        a = x[i][0];
        b = x[i][1];
        c = y[i][0];
        d = y[i][1];
        a1 += a;
        b1 += b;
        c1 += c;
        d1 += d;
        a2 += a * a;
        b2 += b * b;
        ac += a * c;
        bd += b * d;
    }

    const n2 = n * n;
    const a12 = a1 * a1;
    const b12 = b1 * b1;
    const u = a2 + b2;
    const v = ac + bd;
    const g = n2 * u - n * (a12 + b12);

    if (Math.abs(g) < epsilon) {
        return new Transform(1, 0, c1 / n - a, d1 / n - b);
    }

    const a1c1 = a1 * c1;
    const b1d1 = b1 * d1;
    const s = (n2 * v - n * (a1c1 + b1d1)) / g;
    const r = 0;
    const tx = (n * (c1 * u - a1 * v) - b12 * c1 + a1 * b1d1) / g;
    const ty = (n * (d1 * u - b1 * v) - a12 * d1 + b1 * a1c1) / g;

    return new Transform(s, r, tx, ty);
}

export function estimateTranslationScalingRotation(x: number[][], y: number[][]): Transform {
    const n = Math.min(x.length, y.length);

    if (n === 0) {
        return new Transform(1, 0, 0, 0);
    }

    let a = x[0][0], b = x[0][1], c = y[0][0], d = y[0][1],
        a1 = a, b1 = b, c1 = c, d1 = d,
        a2 = a * a, b2 = b * b, ac = a * c,
        ad = a * d, bc = b * c, bd = b * d;

    for (let i = 1; i < n; ++i) {
        a = x[i][0];
        b = x[i][1];
        c = y[i][0];
        d = y[i][1];
        a1 += a;
        b1 += b;
        c1 += c;
        d1 += d;
        a2 += a * a;
        b2 += b * b;
        ac += a * c;
        ad += a * d;
        bc += b * c;
        bd += b * d;
    }
    const g = n * (a2 + b2) - a1 * a1 - b1 * b1;

    if (Math.abs(g) < epsilon) {
        return new Transform(1, 0, c1 / n - a, d1 / n - b);
    }

    const acbd = ac + bd;
    const adbc = ad - bc;
    const s = (n * acbd - a1 * c1 - b1 * d1) / g;
    const r = (n * adbc + b1 * c1 - a1 * d1) / g;
    const tx = (-a1 * acbd + b1 * adbc + a2 * c1 + b2 * c1) / g;
    const ty = (-b1 * acbd - a1 * adbc + a2 * d1 + b2 * d1) / g;
    return new Transform(s, r, tx, ty);
}

export function trim(s: string): string {
    s = s.replace(/^\s+/, '');
    s = s.replace(/\s+/g, ' ');
    s = s.replace(/\s+$/, '');
    return s;
}

/*
declare global {
    interface HTMLElement {
        webkitRequestFullscreen: (elem: HTMLElement) => Promise<void>;
        mozRequestFullScreen: (elem: HTMLElement) => Promise<void>;
        msRequestFullscreen: (elem: HTMLElement) => Promise<void>;
    }
    interface Document {
        webkitExitFullscreen: () => void;
        mozCancelFullScreen: () => void;
        msExitFullscreen: () => void;
    }
}
*/

/*
const root = document.documentElement;
export function getReqFullscreen(): (options?: FullscreenOptions) => Promise<void> {
    return root.requestFullscreen || root.webkitRequestFullscreen || root.mozRequestFullScreen || root.msRequestFullscreen;
}
export function getExitFullscreen(): () => Promise<void> {
    return document.exitFullscreen || document.webkitExitFullscreen || document.mozCancelFullScreen || document.msExitFullscreen;
}
*/

export function escapeRegExp(str: string): string {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export async function fixP<T>(p: (a: T) => Promise<T>, x: T): Promise<T> {
    const y = await p(x);
    return await fixP(p, y);
}

/*
export function log(...args: unknown[]): void {
    //const args = Array.prototype.slice.call(arguments)
    //, n = document.createTextNode('practique-ui: ' + JSON.stringify(args))
    //, s = document.createElement('div')
    //;
    console.log('practique-ui', ...args)

    //s.className = 'debug-log';
    //s.appendChild(n);
    //wi.log.appendChild(s);
    //wi.log.scrollTop = wi.log.scrollHeight - wi.log.clientHeight;
}
export function error(...args: unknown[]): void {
    //const args = Array.prototype.slice.call(arguments)
    //, n = document.createTextNode('practique-ui: ' + JSON.stringify(args))
    //, s = document.createElement('div')
    //;
    console.error('practique-ui:', ...args);

    //s.className = 'error-log';
    //s.appendChild(n);
    //wi.log.appendChild(s);
    //wi.log.scrollTop = wi.log.scrollHeight - wi.log.clientHeight;
}
*/

function scrollParent(elem: HTMLElement): {parent: HTMLElement|null, offset: number} {
    let offset = elem.offsetTop;
    let parent: HTMLElement|null = elem;
    while (parent && parent.scrollHeight <= parent.clientHeight) {
        parent = parent.parentElement;
    }
    if (parent) {
        offset -= parent.offsetTop;
    }
    return {parent, offset};
}

export function scrollRangeIntoView(first: HTMLElement, last: HTMLElement = first): void {
    if (first) {
        const {parent, offset} = scrollParent(first);
        if (parent && parent instanceof HTMLElement) {
            if (offset < parent.scrollTop) {
                parent.scrollTop = offset;
            } else {
                const {parent, offset} = scrollParent(last);
                if (parent && parent instanceof HTMLElement) {
                    const bottom = offset + last.offsetHeight;
                    const end = parent.clientHeight;
                    if (bottom - end > parent.scrollTop) {
                        parent.scrollTop = bottom - end;
                    }
                }
            }
        }
    }
}

export function detectBrowser(): string {
    if(navigator.vendor.match(/google/i)) {
        return 'chrome/blink';
    } else if(navigator.vendor.match(/apple/i)) {
        return 'safari/webkit';
    } else if(navigator.userAgent.match(/firefox\//i)) {
        return 'firefox/gecko';
    } else if(navigator.userAgent.match(/edge\//i)) {
        return 'edge/edgehtml';
    } else if(navigator.userAgent.match(/trident\//i)) {
        return 'ie/trident';
    } else {
        return navigator.userAgent + "\n" + navigator.vendor;
    }
}

export function readBlob(blob: Blob): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (): void => {
            if (reader.result instanceof ArrayBuffer) {
                resolve(reader.result);
            } else {
                reject('Not an ArrayBuffer.');
            }
        };
        reader.readAsArrayBuffer(blob);
    });
}

type TreeLike<T> = T|TreeLike<T>[];
export function flatten<T>(arr: TreeLike<T>, result: T[]): void {
    if (Array.isArray(arr)) {
        for (let i = 0; i < arr.length; ++i) {
            flatten(arr[i], result);
        }
    } else {
        result.push(arr);
    }
}

export function getPixelScale(): {x: number, y: number} {
    let x = window.devicePixelRatio || 1.0;
    let y = window.devicePixelRatio || 1.0;
    if (window.visualViewport && window.visualViewport.scale != 1) {
        x *= window.visualViewport.scale;
        y *= window.visualViewport.scale;
    } else if (detectBrowser() == 'safari/webkit') {
        x *= document.documentElement.clientWidth / window.innerWidth;
        y *= document.documentElement.clientHeight / window.innerHeight;
    }

    x = Math.min(x, y);
    y = x;
    return {x, y};
}

export function safeJsonParse(text: string): unknown {
    try {
        return JSON.parse(text) as unknown;
    } catch(err) {
        console.error(`JsonError ${String(err)}\n${text}`);
    }
    return null;
}

export function roundTo(n: number, d: number): number {
    const m = 10 ** d;
    return Math.round(n * m) / m;
}

export function deref<T>(src: Array<T>, ix: Array<number>): Array<T> {
    const dst = new Array<T>(ix.length);
    for(let i = 0; i < ix.length; ++i) {
        dst[i] = src[ix[i]];
    }
    return dst;
}

export async function getFreeSpace(): Promise<{used?: string, quota?: string}> {
    // eslint-disable-next-line compat/compat
    const estimate = await navigator?.storage?.estimate?.();
    if (estimate && estimate.quota && estimate.usage) {
        return {
            used: Math.floor(estimate.usage / 1000000).toLocaleString(),
            quota: Math.round(estimate.quota / 1000000).toLocaleString(),
        };
    }
    return {};
}

export function isIndexed(x: unknown): x is {[prop:string]: unknown} {
    return typeof x === 'object' && x !== null;
}

const blacklist = new Set([
    'html','base', 'head', 'link', 'meta', 'style', 'title', 'col',
    'colgroup', 'table', 'tbody', 'thead', 'tr', 'slot', 'template'
]);

export function canContainPhrasingContent(x: HTMLElement): boolean {
    const tagName = x.tagName.toLowerCase();
    return !blacklist.has(tagName);
}
