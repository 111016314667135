import { icon, IconDefinition } from '@fortawesome/fontawesome-svg-core';

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface Attributes {
            id?: string,
            title?: string,
            tabIndex?: number,
            hidden?: boolean,
            disabled?: boolean,
            style?: Partial<CSSStyleDeclaration>,
            className?: string,
            'aria-label'?: string,
        }
        interface IntrinsicElements {
            div: Attributes;
            span: Attributes;
            img: Attributes & {alt: string, draggable: boolean, src: string};
            button: Attributes;
            input: {type: string, size?: number, value?: string} & Attributes;
        }
    }
}

function DOMparseChildren(children: (string|Node|HTMLCollection)[]): Node[] {
    return children.reduce((acc: Node[], child) => {
        if (typeof child === 'string') {
            acc.push(document.createTextNode(child));
        } else if (child instanceof HTMLCollection) {
            for (let i = 0; i < child.length; ++i) {
                acc.push(child[i]);
            }
        } else {
            acc.push(child);
        }
        return acc;
    }, []);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DOMparseAttributes(element: HTMLElement, properties: {[index:string]: any}): void {
    for (const [key, value] of Object.entries(properties)) {
        switch (key) {
            case 'id':
                element.id = properties.id;
                break;
            case 'title':
                element.title = properties.title;
                break;
            case 'tabIndex':
                element.tabIndex = properties.tabIndex;
                break;
            case 'hidden':
                element.hidden = properties.hidden;
                break;
            case 'disabled':
                if (element instanceof HTMLInputElement) {
                    element.disabled = properties.disabled;
                }
                break;
            case 'className':
                element.className = properties.className;
                break;
            case 'style':
                Object.assign(element.style, properties.style);
                break;
            default:
                element.setAttribute(key, value);
                break;
        }
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function DOMparseNode(element: string, properties: {[index:string]: any}, children: (string|Node)[]): Node {
    const el = document.createElement(element);
    DOMparseAttributes(el, properties)
    for (const child of DOMparseChildren(children)) {
        el.appendChild(child);
    }
    return el;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunElem = (args: {[index: string]: any}) => Node;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function DOMcreateElement(element: FunElem|string, properties?: {[index: string]: any}, ...children: (string|Node)[]): Node {
    properties ??= {};
    if (typeof element === 'function') {
        return element({...properties, children});
    }
    return DOMparseNode(element, properties, children);
}

export function Icon(props: {icon: IconDefinition} & JSX.Attributes): HTMLSpanElement {
    const {icon: iconDef, ...rest} = props;
    const elem = document.createElement('span');
    DOMparseAttributes(elem, rest);
    for (const n of Array.from(icon(iconDef).node)) {
        elem.appendChild(n);
    }
    return elem;
}

