import { mkNode } from './utils';
import { faCat, faPaw } from '@fortawesome/free-solid-svg-icons';
import { ControlPanel } from 'question-base';
import { version } from '@gen/version';

const shortVersion = version.match(/^p4b-(\d+\.\d+\.\d+)/)?.[1];
const debugVersion = version.match(/^\S+\s+DEBUG\s+\[(\S+\s+)+(\d+):(\d+):\d+(\s+\S+)+\]$/);

console.debug('ROBOT_CAT', shortVersion, debugVersion);

export class RobotCat {
    private controlPanel: ControlPanel;
    private catButton?: HTMLButtonElement;
    private iconOff: HTMLSpanElement;
    private iconOn: HTMLSpanElement;
    private interval?: number;

    private counter = 0;

    private readonly handleInterval = async () => {
        switch (Math.floor(Math.random() * 3)) {
            case 0:
                console.debug('ROBOT_CAT next');
                document.getElementById('next-button')?.click();
                break;
            case 1:
                console.debug('ROBOT_CAT previous');
                document.getElementById('prev-button')?.click();
                break;
            case 2:
                const opts = [
                    //...document.getElementsByClassName('app-button'),
                    ...document.getElementsByClassName('figure'),
                    ...document.getElementsByClassName('flag'),
                    ...document.getElementsByClassName('sba-option'),
                    ...document.getElementsByClassName('sba-option-del'),
                    ...document.getElementsByClassName('long-answer'),
                    ...document.getElementsByClassName('short-answer'),
                ];
                if (opts.length > 0) {
                    const opt = opts[Math.floor(Math.random() * opts.length)];
                    if (opt instanceof HTMLButtonElement || opt instanceof HTMLDivElement) {
                        const {top, left} = opt.getBoundingClientRect();
                        const event = new MouseEvent('click', {
                            clientX: left + 1,
                            clientY: top + 1,
                            bubbles: true,
                        });
                        opt.dispatchEvent(event);
                    } else if (opt instanceof HTMLTextAreaElement || opt instanceof HTMLInputElement) {
                        opt.value = String(this.counter++);
                        const event = new Event('change');
                        opt.dispatchEvent(event);
                    }
                }
                break;
            
        }
    };

    private readonly handleCatButton = () => {
        if (this.interval === undefined) {
            console.debug('ROBOT_CAT START');
            this.interval = window.setInterval(this.handleInterval, 100);
            this.catButton?.replaceChild(this.iconOn, this.iconOff);
        } else {
            console.debug('ROBOT_CAT STOP');
            window.clearInterval(this.interval);
            this.interval = undefined;
            this.catButton?.replaceChild(this.iconOff, this.iconOn);
        }
    };

    constructor(controlPanel: ControlPanel) {
        this.controlPanel = controlPanel;
        this.iconOff = mkNode('icon', {icon: faCat});
        this.iconOn = mkNode('icon', {icon: faPaw});
        if (debugVersion) {
            this.catButton = mkNode('button', {
                className: 'app-button config-primary-hover config-primary-fg-shadow-focus',
                children: [
                    this.iconOff,
                    mkNode('span', {className: 'app-button-text', children: [
                        mkNode('text', {text: `${shortVersion}.${debugVersion[2]}.${debugVersion[3]}`}),
                    ]})
                ]
            });
            this.controlPanel.add(this.catButton)
            this.catButton.addEventListener('click', this.handleCatButton);
        }
    }

    public async destroy(): Promise<void> {
        if (this.interval !== undefined) {
            window.clearInterval(this.interval);
            this.interval = undefined;
        }
        if (this.catButton) {
            this.catButton.removeEventListener('click', this.handleCatButton);
            this.controlPanel.remove(this.catButton);
            this.catButton = undefined;
        }
    }
}
