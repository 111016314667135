import { isIndexed, mkNode, removeChildren, removeNode } from 'utils';
import { Structure } from 'exam-service';
import {
    Question, QuestionContext, QuestionManifest, Expr, ExprObject,
    ExprVal, ExprRef, ExprEq, ExprGt, ExprLt, ExprGe, ExprLe, ExprAnd, ExprOr, ExprXor, ExprNot, answerTypes
} from 'question-base';
import { ComponentDetails, MeetingEvent } from 'meeting';
import { dbGet } from 'utils-db';
import { ThumbnailViewer } from 'thumbnail-viewer';
import { Lightbox } from 'lightbox'
import ResizeObserver from 'resize-observer-polyfill';
import { translate } from 'utils-lang';
import { ThumbnailObserver } from './thumbnail-base';
import { confirmModal } from './utils-progress';

declare global {
    interface Performance {
        memory?: {
            totalJSHeapSize: number,
            usedJSHeapSize: number,
            jsHeapSizeLimit: number,
        }
    }
}

function logMemoryUsed(tag = '') {
    //eslint-disable-next-line compat/compat
    const memory = window?.performance?.memory;
    if (memory && memory.usedJSHeapSize) {
        console.log(`${tag?`${tag} `:''}MEMORY ${memory.usedJSHeapSize / 1048576} MB`);
    } else if (tag) {
        console.log(`${tag} MEMORY API not available`);
    }
}

function isExprVal(x: ExprObject): x is ExprVal {
    return (x as ExprVal).value != undefined;
}

function isExprRef(x: ExprObject): x is ExprRef {
    return (x as ExprRef).backend_id != undefined;
}

function isExprEq(x: Expr): x is ExprEq {
    return (x as ExprEq).eq != undefined && (x as ExprEq).eq.length == 2;
}

function isExprGt(x: Expr): x is ExprGt {
    return (x as ExprGt).gt != undefined && (x as ExprGt).gt.length == 2;
}

function isExprLt(x: Expr): x is ExprLt {
    return (x as ExprLt).lt != undefined && (x as ExprLt).lt.length == 2;
}

function isExprGe(x: Expr): x is ExprGe {
    return (x as ExprGe).ge != undefined && (x as ExprGe).ge.length == 2;
}

function isExprLe(x: Expr): x is ExprLe {
    return (x as ExprLe).le != undefined && (x as ExprLe).le.length == 2;
}

function isExprAnd(x: Expr): x is ExprAnd {
    return (x as ExprAnd).and != undefined && (x as ExprAnd).and.length > 1;
}

function isExprOr(x: Expr): x is ExprOr {
    return (x as ExprOr).or != undefined && (x as ExprOr).or.length > 1;
}

function isExprXor(x: Expr): x is ExprXor {
    return (x as ExprXor).xor != undefined && (x as ExprXor).xor.length > 1;
}

function isExprNot(x: Expr): x is ExprNot {
    return (x as ExprNot).not != undefined;
}

async function evalExprString(expr: ExprObject, find: (id: number) => Promise<string>): Promise<string> {
    if (isExprRef(expr)) {
        return await find(expr.backend_id);
    } else if (isExprVal(expr)) {
        return expr.value;
    }
    return '';
}

export async function evalExprBool(expr: Expr, find: (id: number) => Promise<string>): Promise<boolean> {
    if (isExprEq(expr)) {
        return await evalExprString(expr.eq[0], find) === await evalExprString(expr.eq[1], find);
    } else if (isExprGt(expr)) {
        return await evalExprString(expr.gt[0], find) > await evalExprString(expr.gt[1], find);
    } else if (isExprLt(expr)) {
        return await evalExprString(expr.lt[0], find) < await evalExprString(expr.lt[1], find);
    } else if (isExprGe(expr)) {
        return await evalExprString(expr.ge[0], find) >= await evalExprString(expr.ge[1], find);
    } else if (isExprLe(expr)) {
        return await evalExprString(expr.le[0], find) <= await evalExprString(expr.le[1], find);
    } else if (isExprAnd(expr)) {
        let x = await evalExprBool(expr.and[0], find);
        for (let i = 1; i < expr.and.length; ++i) {
            x = x && await evalExprBool(expr.and[i], find);
        }
        return x;
    } else if (isExprOr(expr)) {
        let x = await evalExprBool(expr.or[0], find);
        for (let i = 1; i < expr.or.length; ++i) {
            x = x || await evalExprBool(expr.or[i], find);
        }
        return x;
    } else if (isExprXor(expr)) {
        let x = await evalExprBool(expr.xor[0], find);
        for (let i = 1; i < expr.xor.length; ++i) {
            x = !x != !await evalExprBool(expr.xor[i], find);
        }
        return x;
    } else if (isExprNot(expr)) {
        return !await evalExprBool(expr.not, find);
    }
    return false;
}

/*interface QuestionUi {
    page: HTMLDivElement;
    question: HTMLDivElement;
    title: HTMLDivElement;
    stem: HTMLDivElement;
    answers: HTMLDivElement;
    answerTitle: HTMLDivElement;
    answerText: Text;
    answerPanel: HTMLDivElement;
}*/

export class QuestionViewer implements ThumbnailObserver {
    private page: HTMLDivElement;
    private question: HTMLDivElement;
    private questionInner: HTMLDivElement;
    private title: HTMLHeadingElement;
    private stem: HTMLDivElement;
    private answers: HTMLDivElement;
    private answersInner: HTMLDivElement;
    private answerTitle: HTMLHeadingElement;
    private answerText: Text;
    private answerPanel: HTMLDivElement;

    private context: QuestionContext;
    public components = new Array<Question>();
    private componentMap= new Map<number, number>();
    //private popout?: Window;
    //private meeting: MeetingViewer;
    private thumbnails?: ThumbnailViewer;
    private lightbox: Lightbox;
    private resizeObserver: ResizeObserver;
    private isOsce = false;
    private isRosce = false;
    private isCandidate = false;
    private startTime = 0;
    private questionIndex = -1;
    private previousTimeOnQuestion = 0;
    private validityExpression?: PractiqueNet.ExamJson.Definitions.Expr;
    private round?: number;

    public getExamType(): ('osce'|'written') {
        return (this.isOsce) ? 'osce' : 'written';
    }

    private readonly handleScroll = async (event: Event) => {
        if (event.target instanceof Element) {
            await new Promise(resolve => window.requestAnimationFrame(resolve));
            const {scrollTop, scrollLeft, scrollHeight, clientHeight} = event.target
            , atTop = scrollTop === 0
            , beforeTop = 1
            , atBottom = scrollTop === scrollHeight - clientHeight
            , beforeBottom = scrollHeight - clientHeight - 1
            ;
            if (atTop) {
                event.target.scrollTo(scrollLeft, beforeTop);
            } else if (atBottom) {
                event.target.scrollTo(scrollLeft, beforeBottom);
            }
        }
    }

    private readonly handleResize = (entries: ResizeObserverEntry[]): void => {
        let lastLightbox = undefined;
        let lastQuestion = undefined;
        let lastAnswers = undefined;
        for (const entry of entries) {
            if (entry.target === this.context.parent) {
                lastLightbox = entry;
            } else if (entry.target === this.question) {
                lastQuestion = entry;
            } else if (entry.target === this.answers) {
                lastAnswers = entry;
            }
        }

        if (lastLightbox) {
            this.lightbox.setHeight(lastLightbox.contentRect.height);
        }
        if (lastQuestion) {
            this.question.dispatchEvent(new Event('scroll'));
        }
        if (lastAnswers) {
            this.answers.dispatchEvent(new Event('scroll'));
        }
    };

    //public getMeeting(): MeetingViewer|undefined {
    //    return this.context.meeting;
    //}

    public constructor(context: QuestionContext) {
        this.context = context;
        this.page = mkNode('div', {className: 'qna-panel'});
        this.question = mkNode('div', {className: 'question config-background', parent: this.page});
        this.questionInner = mkNode('div', {className: 'question-inner', parent: this.question});
        this.lightbox = new Lightbox({
            fullscreenParent: this.context.fullscreenParent,
            scrollContainer: this.question,
            getImageBegin: this.context.getImageBegin,
            getImageFrame: this.context.getImageFrame,
            getImageEnd: this.context.getImageEnd,
            getNavigating: this.context.getNavigating,
            //noMouse: this.context.noMouse,
            meta: this.context.meta,
        }, this.context.controlPanel, this.context.meetingBar, this.questionInner);
        this.title = mkNode('h2', {className: 'title config-primary config-body-border-highlight', parent: this.questionInner, children: [
            mkNode('text', {text: 'Question'}),
        ]});
        this.stem = mkNode('div', {className: 'stem break-word', parent: this.questionInner, attrib: {role: 'region', 'aria-live': 'polite'}});
        this.answers = mkNode('div', {className: 'answers', parent: this.page});
        this.answersInner = mkNode('div', {className: 'answers-inner', id: 'answers-inner', parent: this.answers});
        this.answerTitle = mkNode('h2', {className: 'answer-title config-primary config-body-border-highlight', parent: this.answersInner});
        this.answerText = mkNode('text', {text: '\u00a0', parent: this.answerTitle});
        this.answerPanel = mkNode('div', {className: 'answer-panel', parent: this.answersInner});
        context.parent.appendChild(this.page);
        this.question.scrollTop = 1;
        this.answers.scrollTop = 1;
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(context.parent);
        this.resizeObserver.observe(this.question);
        this.resizeObserver.observe(this.answers);
        this.question.addEventListener('visibility', this.handleVisibility);
        this.question.addEventListener('scroll', this.handleScroll);
        this.answers.addEventListener('scroll', this.handleScroll);
    }

    private evalExprBool(expr: Expr): boolean {
        if (isExprEq(expr)) {
            return this.evalExprString(expr.eq[0]) === this.evalExprString(expr.eq[1]);
        } else if (isExprGt(expr)) {
            return this.evalExprString(expr.gt[0]) > this.evalExprString(expr.gt[1]);
        } else if (isExprLt(expr)) {
            return this.evalExprString(expr.lt[0]) < this.evalExprString(expr.lt[1]);
        } else if (isExprGe(expr)) {
            return this.evalExprString(expr.ge[0]) >= this.evalExprString(expr.ge[1]);
        } else if (isExprLe(expr)) {
            return this.evalExprString(expr.le[0]) <= this.evalExprString(expr.le[1]);
        } else if (isExprAnd(expr)) {
            let x = this.evalExprBool(expr.and[0]);
            for (let i = 1; i < expr.and.length; ++i) {
                x = x && this.evalExprBool(expr.and[i]);
            }
            return x;
        } else if (isExprOr(expr)) {
            let x = this.evalExprBool(expr.or[0]);
            for (let i = 1; i < expr.or.length; ++i) {
                x = x && this.evalExprBool(expr.or[i]);
            }
            return x;
        } else if (isExprXor(expr)) {
            let x = this.evalExprBool(expr.xor[0]);
            for (let i = 1; i < expr.xor.length; ++i) {
                x = !x != !this.evalExprBool(expr.xor[i]);
            }
            return x;
        } else if (isExprNot(expr)) {
            return !this.evalExprBool(expr.not);
        }
        return false;
    }

    private evalExprString(expr: ExprObject): string {
        if (isExprRef(expr)) {
            const x = this.componentMap.get(expr.backend_id);
            if (x != undefined) {
                return this.components[x].getValue();
            }
        } else if (isExprVal(expr)) {
            return expr.value;
        }
        return '';
    }

    private readonly updateVisibility = (): void => {
        for (const component of this.components) {
            if (component.visibilityExpression) {
                component.setVisible(this.evalExprBool(component.visibilityExpression));
            }
        }
        this.updateValidity();
    }

    private readonly handleVisibility = (e: Event): void => {
        if (e instanceof CustomEvent) {
            this.question.style.display = (e.detail.visibility || this.thumbnails || this.stem.innerHTML || this.isOsce) ? 'block' : 'none';
        }
    }

    private updateValidity(): void {
        for (const component of this.components) {
            if (component?.mandatory && component.getValue() !== '') {
                component.updateValidity(true);
            }
        }
    }

    private async checkValidity(): Promise<boolean> {
        const invalid = [];
        for (const component of this.components) {
            const inv = (component?.mandatory && component.getValue() === '');
            component.updateValidity(!inv);
            if (inv && component.displayId) {
                invalid.push(component.displayId);
                this.context.setInvalid(component.qno, component.ano);
            }
        }
        return invalid.length <= 0 || await confirmModal(translate('MARKSHEET_INVALID', {invalid: invalid.join(' ')}));
    }

    private disableCheckpoint = false;

    public async checkpointQuestion(): Promise<void> {
        if (this.disableCheckpoint) {
            return;
        }
        await this.context.saveAnswer({qno: this.questionIndex, ano: -1}, {
            timeOnQuestion: this.previousTimeOnQuestion + this.context.getTimers().get() - this.startTime,
            nextQuestion: this.questionIndex,
            extra: {connectedTime: this.context.getTimers().get('connected')},
        });
    }

    // *FIXME* Get question start time from server saved question change data.
    public async setQuestion({structure, language, time, notify = true, forced, validate = false}: {
        structure?: Structure,
        language: number,
        time: number,
        notify?: boolean,
        forced: boolean,
        validate?: boolean,
    }): Promise<boolean> {
        if (validate && !forced && !await this.checkValidity()) {
            return false;
        }
        try {
            if (this.questionIndex >= 0 && notify) {
                // If there was a previous question loaded, store the timeOnQuestion as we are navigating away.
                await this.context.saveAnswer({qno: this.questionIndex, ano: -1}, {
                    timeOnQuestion: this.previousTimeOnQuestion + time - this.startTime,
                    nextQuestion: structure?.question,
                    extra: {connectedTime: this.context.getTimers().get('connected')},
                });
            }

            // Destroy resources of previous question.
            //console.debug('close lightbox');
            await this.lightbox.closeAll();

            try {
                this.disableCheckpoint = true; // don't save again on meeting end as we have just saved above
                //this.setResourceStatus({released: false});
                //this.context.meeting.sendStatus();
                //console.debug('destroy meetings');
                //if (this.meeting) {
                //    await this.meeting.destroy();
                //    this.meeting = undefined;
                //}
            } finally {
                this.disableCheckpoint = false;
            }

            if (this.thumbnails) {
                this.thumbnails.destroy();
                this.thumbnails = undefined;
            }

            // Load new question and previously saved timeOnQuestion before destroying anything.
            const backendQid = structure && structure.backendQid[language ?? 0];
            const question = (structure && backendQid !== undefined) ?
                await dbGet<QuestionManifest>('questions', structure.backendQid[language ?? 0]) : undefined;
            const savedTime = structure && await this.context.loadAnswer(structure.question, -1)
            if (savedTime && savedTime.timeOnQuestion) {
                this.previousTimeOnQuestion = savedTime.timeOnQuestion
            } else {
                this.previousTimeOnQuestion = 0;
            }
            if (structure?.round == undefined || structure.round != this.round) {
                this.round = structure?.round;
                console.debug('CONNECTION_TIME LOAD');
                if (savedTime && isIndexed(savedTime.extra) && typeof savedTime.extra.connectedTime === 'number') {
                    this.context.getTimers().set('connected', savedTime.extra.connectedTime);
                } else {
                    this.context.getTimers().set('connected', 0);
                }
            }

            this.title.innerHTML = '&nbsp;'
            removeChildren(this.stem);
            for (const component of this.components) {
                component.destroy();
            }
            this.components = [];
            this.componentMap.clear();
            removeChildren(this.answerPanel);

            // If no new question to load, finish here.
            if (!structure) {
                return false;
            }

            // Load new question.
            const pos = structure.question;
            const factor = structure.factor;
            const interview = structure.interview;

            this.startTime = time;
            this.questionIndex = pos;
            this.isOsce = structure.room != null;
            this.isRosce = interview !== undefined && factor !== undefined;
            this.isCandidate = this.context.component === ComponentDetails.ROLE_CANDIDATE;

            //console.debug('QUESTION LOADED', question);

            const loaders = [];

            let title = '';
            if ((structure.round != null) || (structure.room != null) || (structure.circuit != null)) {
                const ts = [];
                if (structure.round != null) {
                    ts.push(`${translate('TITLE_ROUND')} <span style="font-weight:700;">${structure.round}</span>`);
                }
                if (structure.room != null) {
                    ts.push(`${translate('TITLE_STATION')} <span style="font-weight:700;">${structure.room}</span>`);
                }
                if (structure.circuit != null) {
                    ts.push(`${translate('TITLE_CIRCUIT')} <span style="font-weight:700;">${structure.circuit}</span>`);
                }
                title += ts.join(', ');
            } else {
                title += '<span style="font-weight:700;">' + (1 + pos) + '</span>'
            }
            if (question && question.manifest.title && this.context.meta.show_question_title) {
                title += ': ' + question.manifest.title;
            }
            if (structure.case && structure.ofCases && structure.ofCases > 1) {
                this.title.innerHTML = `<div style="display:flex"><span style="flex:1">${title}</span><span>${translate('TITLE_CASE', {n: structure.case, m:structure.ofCases})}</span></div>`;
            } else {
                this.title.innerHTML = title;
            }

            if (question && (question.manifest.stem || question.images.length > 0) || structure.round != null) {
                if (question && question.manifest.stem) {
                    this.stem.innerHTML = question.manifest.stem;
                    this.stem.style.display = 'block';
                } else {
                    this.stem.innerHTML = '';
                    this.stem.style.display = 'none';
                }
                if (question && question.images.length > 0) {
                    this.thumbnails = new ThumbnailViewer({
                        fullscreenParent: this.context.fullscreenParent,
                        scrollContainer: this.question,
                        sizeReference: this.page,
                        getImageBegin: this.context.getImageBegin,
                        getImageFrame: this.context.getImageFrame,
                        getImageEnd: this.context.getImageEnd,
                        getNavigating: this.context.getNavigating,
                        setNavigating: this.context.setNavigating,
                        isRemoteShowHide: this.isRosce && this.context.component !== ComponentDetails.ROLE_EXAMINER,
                    }, this.questionInner, question.images, this.lightbox);
                    this.thumbnails.disabled((!this.context.meta.disableResourceLocking) && (question.manifest.answers.length < 1) && (structure.interview !== undefined));
                    loaders.push(this.thumbnails.loadResources(question.thumbnails));
                }
                this.question.style.display = 'block';
            } else {
                this.question.style.display = 'none';
            }

            title = '';
            if (structure.room != null) {
                if (factor !== undefined) {
                    if (structure.interview) {
                        title += translate('TITLE_CONNECT_TO', {factor});
                    } else {
                        title += '<b>' + factor + '</b>';
                    }
                    const details = this.context.factorDetails?.[factor];
                    if (details && (details.family_name || details.given_name)) {
                        title += ': ';
                        if (details.family_name) {
                            title += details.family_name;
                        }
                        if (details.family_name && details.given_name) {
                            title += ', ';
                        }
                        if (details.given_name) {
                            title += details.given_name;
                        }
                    }
                } else {
                    title += translate('NO_CANDIDATE');
                }
                this.answers.style.display = 'block'
            } else if (question && question.manifest.answers.length > 0) {
                title = translate('ANSWER_TITLE');
                this.answers.style.display = 'block'
            } else {
                this.answers.style.display = 'none';
            }
            this.answerTitle.innerHTML = title;

            if (question) {
                if (question.manifest.answers.length > 0) {
                    this.validityExpression = question.manifest.valid;
                    const frag = document.createDocumentFragment();
                    let i = 0;
                    for (let j = 0; j < question.manifest.answers.length; ++j) {
                        const answer = question.manifest.answers[j];
                        for (const t of answerTypes) {
                            if (t.isThis(answer, question.manifest.answers.length)) {
                                //console.log('QUESTION COMPONENT', question);
                                const component = t.makeAnswer(
                                    pos,
                                    this.context,
                                    this.updateVisibility,
                                    question,
                                    answer,
                                    frag,
                                    j,
                                    this.lightbox,
                                    this.isRosce && this.context.component !== ComponentDetails.ROLE_EXAMINER,
                                    this.isOsce,
                                );
                                if (this.isReadOnly) {
                                    component.setReadOnly(true);
                                }
                                this.componentMap.set(answer.backend_id, j);
                                if (answer.backend_id >= 0) {
                                    const answerResources = question.answersResources[i++];
                                    if (answerResources) {
                                        loaders.push(component.loadResources(answerResources.thumbnails));
                                    }
                                }
                                this.components.push(component);
                                break;
                            }
                        }
                    }
                    this.updateVisibility();
                    this.answerPanel.appendChild(frag);
                    loaders.push(this.loadFlags());
                    loaders.push(this.loadAnswers());
                }
            }

            console.warn('INTERVIEW', interview);
            if (interview) { // (this.isRosce && interview) {
                //this.meetingMessage = translate('NOTIFICATION_CONNECT');
                //this.context.notificationArea.innerHTML = this.meetingMessage;
                //this.context.notificationArea.className= 'message-warning';
                /*this.meeting = new MeetingViewer(
                    this.context.setNavigating,
                    this.context.controlPanel,
                    this.context.meetingBar,
                    this.context.meta.answer_aes_key,
                    this.context.candidateId,
                    this,
                    interview,
                    this.context.component,
                );*/
                this.thumbnails?.addObserver(this);
                this.resourceStatus.forEach((value, key) => {
                    this.setResourceStatusOnly({id: key, released: value});
                });
                this.context.meeting.setFactor(factor)
                await this.context.meeting.setInterview(this.context.meta.answer_aes_key, this.context.candidateId, interview);
                this.updateStatus(this.context.meeting.getRoles());
            } else {
                await this.context.meeting.setInterview();
            }
            //else {
                //this.context.notificationArea.className= 'message-hidden';
            //}

            //loaders.push(dbPut('users', 'question', {
            //    question: structure.question,
            //    language: language,
            //    time: time,
            //}));
            //console.debug('AWAITING LOADERS');
            await Promise.all(loaders);
            //console.debug('QUESTION LOADED');
            return true;
        } finally {
            for (const component of this.components) {
                component.loadingComplete();
            }
            logMemoryUsed(`QUESTION ${this.questionIndex} LOADED`);
            //this.page.style.minHeight = String(this.context.parent.clientHeight + 3) + 'px';
            this.context.parent.scrollTop = 1;
            this.question.scrollTop = 1;
            this.answers.scrollTop = 1;
            //if (this.context.meeting && structure?.interview) {
            //    this.context.meeting.enable();
            //}
        }
    }

    public meetingMessage: string|null = null;
    private connectionInterval: number|undefined;

    private updateStatus(roles: Map<string, number>): void {
        console.warn('ROLES', roles, this.isRosce, this.isCandidate, roles.get('examiner'));
        if (!this.context.meta.disableResourceLocking) {
            if (this.isRosce && this.isCandidate) {
                this.setResourceLocked((roles.get('examiner') ?? 0) < 1);
            }
        }
        if (this.isRosce) {
            switch(this.context.component) {
                case ComponentDetails.ROLE_CANDIDATE:
                    if ((roles.get('examiner') ?? 0) > 0) {
                        this.context.meeting.requestStatus();
                        if (this.context.getTimers().start('connected')) {
                            this.checkpointQuestion();
                        }
                    } else {
                        this.setResourceStatus();
                        if (this.context.getTimers().stop('connected')) {
                            this.checkpointQuestion();
                        }
                    }
                    break;
                case ComponentDetails.ROLE_EXAMINER:
                    if ((roles.get('candidate') ?? 0) > 0) {
                        this.disableShowHide(false);
                        if (this.context.getTimers().start('connected')) {
                            this.checkpointQuestion();
                        }
                    } else {
                        this.disableShowHide(true);
                        if (this.context.getTimers().stop('connected')) {
                            this.checkpointQuestion();
                        }
                    }
                    break;
                default:
                    if (((roles.get('candidate') ?? 0) > 0) && ((roles.get('examiner') ?? 0) > 0)) {
                        this.context.meeting.requestStatus();
                        if (this.context.getTimers().start('connected')) {
                            this.checkpointQuestion();
                        }
                    } else {
                        this.setResourceStatus();
                        if (this.context.getTimers().stop('connected')) {
                            this.checkpointQuestion();
                        }
                    }
                    break;
            }
        }
    }

    public async handleMeetingEvent(event: MeetingEvent): Promise<void> {
        switch (event.type) {
        case 'connectedRoles':
            this.context.meeting.sendTime({
                type: 'connectionTime',
                round: this.questionIndex,
                connectionTime: this.context.getTimers().get('connected')
            });
            if (event.roles instanceof Map) {
                this.updateStatus(event.roles);
                //if (arg.size > 0) {
                //    this.context.notificationArea.className = 'message-hidden';
                //    this.meetingMessage = null;
                //}
            }
            break;
        case 'message':
            if (event.html) {
                this.meetingMessage = event.html;
                this.context.notificationArea.innerHTML = this.meetingMessage;
                this.context.notificationArea.className= 'message-warning';
            } else {
                this.context.notificationArea.className = 'message-hidden';
                this.meetingMessage = null;
            }
            break;
        case 'connectionTime':
            //console.debug('DELAY START');
            //await wait(5000);
            //console.debug('DELAY END');
            if (event.round !== this.questionIndex) {
                console.warn('CONNECTION TIME FROM PREVIOUD ROUND', event);
            } else {
                console.debug('CONNECTION TIME RECEIVED', event);
                const timers = this.context.getTimers();
                timers.set('connected', Math.max(event.connectionTime, timers.get('connected')));
            }
            break;
        }
    }

    private resourceStatus = new Map<string, boolean>();

    public handleSendStatus(status: {id: string, released: boolean}): void {
        this.context.meeting.sendStatus([status]);
        this.resourceStatus.set(status.id, status.released);
    }

    public getResourceStatus(status: {id: string, released: boolean}[]): {id: string, released: boolean}[] {
        Array.from(this.resourceStatus.entries()).map(([id, released]) => {
            status.push({id, released})
        });
        //this.thumbnails?.getStatus(status);
        //for (const component of this.components) {
        //    component.thumbnails?.getStatus(status);
        //}
        return status;
    }

    private setResourceStatusOnly(status: {id?: string, released: boolean}): void {
        this.thumbnails?.setStatus(status);
        for (const component of this.components) {
            component.thumbnails?.setStatus(status);
        }
    }

    public setResourceStatus(status?: {id: string, released: boolean}): void {
        if (status === undefined) {
            this.setResourceStatusOnly({released: false});
            this.resourceStatus.clear();
        } else {
            this.setResourceStatusOnly(status);
            this.resourceStatus.set(status.id, status.released);
        }
    }

    private isNavigationLocked = false;
    private isResourceLocked = false;
    private isReadOnly = false;

    private updateLocks(isNavigating = this.context.getNavigating()) {
        const disableResourceViewing = this.isResourceLocked;
        const disableResourceOpen = disableResourceViewing || isNavigating;
        const disableWrite = this.isReadOnly || isNavigating;
        this.lightbox.disabled(disableResourceViewing);
        this.thumbnails?.disabled(disableResourceOpen);
        for (const component of this.components) {
            component.disableResources(disableResourceOpen);
            component.setReadOnly(disableWrite);
        }
        //if (disableResources) {
        //    this.lightbox.closeAll(); // async
        //}
    }

    public getReadOnly(): boolean {
        return this.isReadOnly;
    }

    public setReadOnly(isReadOnly: boolean): void {
        this.isReadOnly = isReadOnly;
        for (const component of this.components) {
            component.setReadOnly(isReadOnly);
        }
        this.context.setNavigating();
    }

    public setNavigating(isNavigating: boolean): void {
        this.updateLocks(isNavigating);
    }

    public getNavigationLocked(): boolean {
        return this.isNavigationLocked;
    }

    public setNavigationLocked(isLocked: boolean): void {
        if (isLocked != this.isNavigationLocked) {
            this.isNavigationLocked = isLocked;
            this.context.setNavigating();
        }
    }

    public setAllLocked(isLocked: boolean): void {
        this.setNavigationLocked(isLocked);
        this.setResourceLocked(isLocked);
        this.setReadOnly(isLocked);
    }

    public getResourceLocked(): boolean {
        return this.isResourceLocked;
    }

    public setResourceLocked(isResourceLocked: boolean): void {
        isResourceLocked &&= !this.context.meta.disableResourceLocking && this.isCandidate;
        this.isResourceLocked = isResourceLocked;
        this.updateLocks();
    }

    public disableShowHide(disable: boolean): void {
        this.thumbnails?.disableShowHide(disable);
        for (const component of this.components) {
            component.thumbnails?.disableShowHide(disable);
        }
    }

    public async destroy(): Promise<void> {
        this.answers.removeEventListener('scroll', this.handleScroll);
        this.question.removeEventListener('scroll', this.handleScroll);
        this.question.removeEventListener('visibility', this.handleVisibility);

        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }

        removeNode(this.page);

        if (this.thumbnails) {
            this.thumbnails.destroy();
            this.thumbnails = undefined;
        }

        await this.lightbox.destroy();

        for (const component of this.components) {
            component.destroy();
        }
        this.components = [];
        this.componentMap.clear();

        //if (this.meeting) {
        //    await this.meeting.destroy();
        //    this.meeting = undefined;
        //}
        //removeChildren(this.context.parent);
    }

    private async loadFlags(): Promise<void> {
        for (const component of this.components) {
            await component.loadFlag();
        }
    }

    private async loadAnswers(): Promise<void> {
        for (const component of this.components) {
            const response = await this.context.loadAnswer(component.qno, component.ano);
            component.loadAnswer(response);
        }
    }

    public setFocus(n: number): void {
        this.components[n]?.focus();
    }

    /*
    public async openResource(n: number, usePopout = false): Promise<void> {
        if (this.context.getNavigating()) {
            return;
        }
        this.context.setNavigating(true);
        try {
            const resource = this.resources[n];
            if (resource instanceof ResourceThumbnail) {
                for (let i = 0; i < this.resources.length; ++i) {
                    this.resources[i].select(i === n);
                }

                if (usePopout) {
                    this.popout = window.open('', 'Image Viewer') || undefined;
                }
                if (this.popout && !this.popout.closed) {
                    if (this.popout.document.styleSheets.length == 0) {
                        const s = this.popout.document.createElement('link');
                        s.type = 'text/css';
                        s.rel = 'stylesheet';
                        s.href = location.protocol + '//' + location.hostname + location.pathname + 'css/main.css';
                        this.popout.document.head.appendChild(s);
                    }
                    if (!this.viewer) {
                        this.viewer = new DicomViewer({
                            parent: this.popout.document.body,
                            fullscreenParent: this.popout.document.body,
                            scrollContainer: this.popout.document.body,
                            after: null,
                            sizeReference: this.popout.document.body,
                            getImageBegin: this.context.getImageBegin,
                            getImageFrame: this.context.getImageFrame,
                            getImageEnd: this.context.getImageEnd,
                            getNavigating: this.context.getNavigating,
                            noMouse: this.context.noMouse,
                            window: this.popout,
                            forceFullscreen: true,
                        });
                        this.viewer.onclose = (): void => {
                            resource.select(false);
                            this.scrollToTop();
                            this.viewer = undefined;
                        };
                    }
                } else {
                    if (!this.viewer) {
                        this.viewer = new DicomViewer({
                            parent: this.ui.question,
                            fullscreenParent: this.context.fullscreenParent,
                            scrollContainer: this.ui.question,
                            after: this.ui.thumbnails,
                            sizeReference: this.ui.page,
                            getImageBegin: this.context.getImageBegin,
                            getImageFrame: this.context.getImageFrame,
                            getImageEnd: this.context.getImageEnd,
                            getNavigating: this.context.getNavigating,
                            noMouse: this.context.noMouse,
                            window: window,
                            forceFullscreen: false, // isTouchDevice,
                        });
                        this.viewer.onclose = (): void => {
                            resource.select(false);
                            this.scrollToTop();
                            this.viewer = undefined;
                        };
                    }
                }
                console.log('SET_DICOM');
                //await this.viewer.setDicom(resource.getRenderer());
            }
        } catch(e) {
            console.error(e);
        } finally {
            this.context.setNavigating(false);
        }
    }*/
}


