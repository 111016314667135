import { mkNode, scrollRangeIntoView, removeNode, isIndexed } from 'utils';
import { LocalData } from 'exam-service';
import { Question, QuestionContext, QuestionManifest, QuestionBase, Expr, registerAnswerType, AnswerKey, AnswerValue, QuestionArgs } from 'question-base';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { Lightbox } from 'lightbox';
import { translate } from 'utils-lang';
import { configDngrPress, configSafePress } from 'exam-accessibility';

type Option = {backendId: string}[]|null;

function isOptionList(opt: unknown): opt is {backendId: string}[] {
    return Array.isArray(opt) && opt.every(x => isIndexed(x) && typeof x.backendId === 'string');
}

function isExclude(x: unknown): x is {excluded: boolean[]} {
    return isIndexed(x) && x.excluded instanceof Array && x.excluded.every(y => typeof y === 'boolean');
}

/** Dropdown question textarea UI */
class QuestionSBA extends QuestionBase implements Question {
    private answerItem: HTMLDivElement;
    //private answerLabel: HTMLDivElement;
    private options: HTMLDivElement;

    private answer: Option;
    private excluded: boolean[];
    private selected: boolean[];
    private backendIds: string[] = [];
    private updateVisibility: () => void;
    //private disabled: boolean;
    private optionText: HTMLDivElement[] = [];
    private optionButtons: HTMLButtonElement[] = [];
    private excludeButtons: HTMLButtonElement[] = [];
    private maxResponses: number;

    public readonly visibilityExpression?: Expr;

    /** Construct Dropdown Question UI */
    public constructor(args: Omit<QuestionArgs, 'showFlag'> & {
        updateVisibility: () => void,
        visibilityExpression?: Expr,
        options: string[],
        optionDetails: PractiqueNet.ExamJson.Definitions.AnswerOptionDetails[],
        optionOrder?: number[],
        maxResponses: number,
    }) {
        super({...args, showFlag: true});
        const {indent, options, optionOrder, context, optionDetails, updateVisibility, visibilityExpression} = args;
        const indentRem = String(1.6 * (indent ?? 0) + 1.6);
        this.maxResponses = args.maxResponses;
        this.label.style.paddingLeft = `${indentRem}rem`;
        this.answerItem = mkNode('div', {className: 'answer-item', parent: this.column});
        //this.answerLabel = mkNode('div', {className: 'answer-label', parent: this.answerItem});
        this.options = mkNode('div', {parent: this.answerItem});
        //console.log('ORIG   ORDER', options)
        //console.log('OPTION ORDER', optionOrder)
        for (let j = 0; j < options.length; ++j) {
            const idx = (optionOrder) ? optionOrder[j] : j;
            const row = mkNode('div', {className: 'sba-option-row', parent: this.options});
            const content = mkNode('div', {className: 'sba-option break-word', parent: row});
            const elem = mkNode('button', {className: 'sba-option-del ' + configSafePress, title: translate('SBA_ANSWER_TIP'), parent: row, tabindex: 0, children: [
                mkNode('icon', {icon: faCheck}),
            ]});
            const exclude = mkNode('button', {className: 'sba-option-del '  + configDngrPress, title: translate('SBA_ELIMINATE_TIP'), tabindex: 0, children: [
                mkNode('icon', {icon: faTimes}),
            ]});
            this.excludeButtons[j] = exclude;
            if (!context.meta.disableAnswerElimination) {
                row.appendChild(exclude);
            }
            content.innerHTML = '<div>' + options[idx] + '</div>' + ((optionDetails[idx]?.description) ? ('<div class="option-details">' + optionDetails[idx]?.description + '</div>') : '');
            this.optionText[j] = content;
            elem.dataset.value = options[idx];
            elem.dataset.backendId = String(optionDetails[idx]?.backend_id);
            this.optionButtons[j] = elem;
            this.backendIds.push(String(optionDetails[idx].backend_id));
        }
        this.updateVisibility = updateVisibility;
        this.visibilityExpression = visibilityExpression;
        //this.answerLabel.appendChild(this.label);
        this.answer = null;
        this.excluded = Array(options.length).fill(false);
        this.selected = Array(options.length).fill(false);
        //frag.appendChild(this.answerItem);
    }

    /** Load any stored answer */
    public loadAnswer(response?: LocalData) {
        try {
            console.debug('LOADED', response);
            if (response && isOptionList(response.answer)) {
                console.debug('OPTION', response);
                if (isExclude(response.extra) && (!this.context.meta.disableAnswerElimination)) {
                    this.excluded = response.extra.excluded;
                }
                if (isOptionList(response.answer)) {
                    this.answer = response.answer;
                    this.answer.forEach(x => {
                        this.selected[this.backendIds.indexOf(x.backendId)] = true;
                    });
                }
            }
            for (let i = 0; i < this.excludeButtons.length; ++i) {
                this.excludeButtons[i].setAttribute('aria-pressed', String(this.excluded[i]));
                this.optionButtons[i].setAttribute('aria-pressed', String(this.selected[i]));
            }
            this.updateVisibility();
        } catch(e) {
            console.error(String(e));
        }
    }

    private updateDisabled() {
        for (let i = 0; i < this.excludeButtons.length; ++i) {
            if (this.isReadOnly) {
                this.optionButtons[i].classList.add('read-only');
            } else {
                this.optionButtons[i].classList.remove('read-only');
            }
            if (this.excluded[i]) {
                this.optionText[i].classList.add('excluded');
            } else {
                this.optionText[i].classList.remove('excluded');
            }
            this.optionButtons[i].disabled = this.isDisabled(this.excluded[i]);
        }
        for (let i = 0; i < this.optionButtons.length; ++i) {
            this.excludeButtons[i].disabled = this.isDisabled(this.selected[i]);
        }
    }

    public setReadOnly(isReadOnly: boolean): void {
        super.setReadOnly(isReadOnly);
        this.updateDisabled();
    }

    public loadingComplete(): void {
        super.loadingComplete();
        this.updateDisabled();
        this.options.addEventListener('click', this.clickHandler);
        //this.options.addEventListener('keydown', this.keydownHandler);
    }

    /** Get the answer value */
    public getValue(): string {
        return JSON.stringify(this.answer);
    }

    /** Set whether this question is visible or hidden */
    public setVisible(vis: boolean): void {
        this.answerItem.style.display = vis ? 'block' : 'none';
        this.context.setVisible(this.qno, this.ano, vis);
    }

    /** Free the resources used by LongtextQuestion */
    public destroy(): void {
        removeNode(this.answerItem);
        this.options.removeEventListener('click', this.clickHandler);
        //this.options.removeEventListener('keydown', this.keydownHandler);
        super.destroy();
    }

    public focus(): void {
        scrollRangeIntoView(this.answerItem, this.answerItem);
    }

    public isDisabled(disable = false): boolean {
        return this.isLoading || this.isReadOnly || disable;
    }

    public getAnswer(): AnswerKey & AnswerValue {
        return {qno: this.qno, ano: this.ano, answer: this.answer, extra: {excluded: this.excluded}};
    }

    private async submit() {
        try {
            await this.context.saveAnswer({qno: this.qno, ano: this.ano}, {answer: this.answer, extra: {excluded: this.excluded}});
        } catch(e) {
            console.error(String(e));
        } finally {
            this.updateVisibility();
        }
    }

    private select(x: number): void {
        if (this.selected[x]) {
            this.selected[x] = false;
        } else {
            if (this.selected.filter(x => x === true).length >= this.maxResponses) {
                return;
            }
            this.selected[x] = true;
        }
        console.debug('SELECT', x);
        const opt = this.optionButtons[x];
        if (this.selected[x]) {
            opt.setAttribute('aria-pressed', 'true');
            this.excludeButtons[x].disabled = this.isDisabled(true);
        } else {
            opt.setAttribute('aria-pressed', "false");
            this.answer = null;
            this.excludeButtons[x].disabled = this.isDisabled(false);
        }
        this.answer = this.selected.reduce((acc, x, i) => {
            if (x) {
                acc.push({backendId: this.backendIds[i]});
            }
            return acc;
        }, [] as {backendId: string}[]);
    }

    private exclude(x: number): void {
        console.debug('EXCLUDE', x);
        this.excluded[x] = !this.excluded[x];
        this.optionButtons[x].disabled = this.isDisabled(this.excluded[x]);
        this.excludeButtons[x].setAttribute('aria-pressed', String(this.excluded[x]));
        if (this.excluded[x]) {
            this.optionText[x].classList.add('excluded');
        } else {
            this.optionText[x].classList.remove('excluded');
        }
    }

    private async update(node: Node): Promise<void> {
        let submit = false;
        for (let i = 0; i < this.optionButtons.length; ++i) {
            if ((this.optionButtons[i].contains(node) || this.optionText[i].contains(node)) && !this.excluded[i]) {
                this.select(i);
                submit = true;
                break;
            }
        }
        for (let i = 0; i < this.excludeButtons.length; ++i) {
            if (this.excludeButtons[i].contains(node) && this.optionButtons[i].getAttribute('aria-pressed') === 'false') {
                this.exclude(i)
                submit = true;
                break
            }
        }
        if (submit) {
            await this.submit();
        }
    }

    private clickHandler = async (e: MouseEvent): Promise<void> => {
        if (!this.isDisabled() && e.target instanceof Node) {
            this.context.setNavigating(true);
            await this.update(e.target);
            this.context.setNavigating(false);
            if (e.target instanceof HTMLElement) {
                e.target.focus();
            }
        }
    }
}

registerAnswerType({
    name: 'SBA',
    isThis: (answer: PractiqueNet.ExamJson.Definitions.Answer): boolean => {
        return answer.type.toLowerCase() === 'mcq';
    },
    makeAnswer: (
        qno: number,
        context: QuestionContext,
        updateVisibility: () => void,
        question: QuestionManifest,
        answer: PractiqueNet.ExamJson.Definitions.AnswerMultiple,
        frag: DocumentFragment,
        ano: number,
        lightbox: Lightbox,
        isRemoteShowHide: boolean,
        isOSCE: boolean,
    ): Question => {
        return new QuestionSBA({
            updateVisibility,
            context,
            qno,
            ano,
            backendQid: question.manifest.backend_id,
            backendAid: answer.backend_id,
            showNumber: question.manifest.answers.length > 1,
            label: answer.label,
            frag,
            options: answer.options,
            optionDetails: answer.optionDetails ?? [],
            lightbox,
            isRemoteShowHide,
            indent: answer.indent,
            visibilityExpression: answer.visible,
            optionOrder: answer.candidateToOptionOrder?.[context.candidateId],
            notes: answer.notes,
            resources: question.answersResources[ano],
            mandatory: answer.mandatory,
            type: answer.type.toLowerCase(),
            isOSCE,
            maxResponses: answer.maxResponses,
        });
    }
});
