import {mkNode, removeChildren, removeNode} from '@p4b/utils';
import { translate } from '@p4b/utils-lang';

export class Progress {
    public onProgress: (progress: number) => Promise<void> = async () => undefined;
    private progressBox: HTMLDivElement;
    private title: HTMLDivElement;
    private subtext: HTMLDivElement;
    private text: Text;
    private totalSize: number;
    private currentSize: number;

    constructor(parent: HTMLElement) {
        this.progressBox = mkNode('div', {className: 'progress-panel'});
        this.title = mkNode('div', {parent: this.progressBox, className: 'progress-title'});
        this.subtext = mkNode('div', {parent: this.progressBox, className: 'progress-subtext'});
        //mkNode('div', {parent: this.progressBox, className: 'spinner'});
        const textBox = mkNode('div', {parent: this.progressBox, className: 'progress-text'});
        this.text = mkNode('text', {parent: textBox});
        this.totalSize = 0;
        this.currentSize = 0;
        parent.appendChild(this.progressBox);
    }

    destroy(): void {
        removeNode(this.progressBox);
    }

    setTitle(title: string): void {
        this.title.innerHTML = title;
    }

    setDescription(description: string): void {
        this.subtext.innerHTML = description;
    }

    setTotalSize(size: number): void {
        this.totalSize = size;
        this.text.nodeValue = '0%';
    }

    async setProgress(progress: number): Promise<void> {
        const p = Math.floor(100.0 * (this.currentSize + progress) / this.totalSize);
        this.text.nodeValue = p + '%';
        await this.onProgress(progress);
    }

    addOffset(progress: number): void {
        this.currentSize += progress;
    }

}

export interface ModalOptions {
    parent?: Node;
    handler: (id:string) => void;
}

export class Modal {
    private overlay: HTMLDivElement;
    private panel: HTMLDivElement;
    private title: HTMLDivElement;
    private text: HTMLDivElement;
    private buttons: HTMLDivElement;
    private options: {[id:string]: HTMLInputElement|HTMLButtonElement} = {};
    private handler: (id:string) => void;
    private previousFocus?: Element;

    constructor({parent = document.body, handler}: ModalOptions) {
        this.overlay = mkNode('div', {className: 'logout-background', parent});
        this.panel = mkNode('div', {className: 'logout-panel config-background-highlight', parent: this.overlay, attrib: {
            role: 'dialog',
            'aria-modal': 'true',
        }});
        this.title = mkNode('div', {className: 'logout-title config-primary', parent: this.panel});
        this.text = mkNode('div', {className: 'logout-text', parent: this.panel});
        this.buttons = mkNode('div', {className: 'logout-buttons', parent: this.panel});
        this.handler = handler;
        this.buttons.addEventListener('click', this.handleClick);
        this.overlay.addEventListener('keyup', this.handleKeyup);
        this.overlay.addEventListener('keydown', this.handleKeydown);
        //this.panel.addEventListener('keydown', this.handleKeydown);
    }

    public destroy(): void {
        this.overlay.removeEventListener('keydown', this.handleKeydown);
        this.overlay.removeEventListener('keyup', this.handleKeyup);
        this.buttons.removeEventListener('click', this.handleClick);
        removeNode(this.overlay);
    }

    public reset(): void {
        this.title.innerHTML = '';
        this.text.innerHTML = '';
        removeChildren(this.buttons);
        this.options = {};
    }

    public addButtons(className: string, buttons: {[id:string]:string}): void {
        for (const id in buttons) {
            const button = mkNode('button', {className: className, parent: this.buttons});
            button.innerHTML = buttons[id];
            this.options[id] = button;
            this.buttons.appendChild(button);
        }
    }

    public addInput(className: string, prompts: {[id:string]:string}): void {
        for (const id in prompts) {
            const label = mkNode('label', {className: className, parent: this.buttons, children: [
                mkNode('text', {text: prompts[id]})
            ]});
            const input = mkNode('input', {className: className, parent: label});
            this.options[id] = input;
            this.buttons.appendChild(label);
        }
    }

    public disable(disable: boolean, id?: string): void {
        if (id === undefined) {
            for (const ix in this.options) {
                this.options[ix].disabled = disable;
                this.options[ix].blur();
            }
        } else {
            const x = this.options[id];
            if (x) {
                x.disabled = disable;
                x.blur();
            }
        }
    }

    public titleHtml(html: string): void {
        this.title.innerHTML = html;
    }

    public bodyHtml(html: string): void {
        this.text.innerHTML = html;
    }

    public show(): void {
        this.overlay.style.display = 'block';
        this.panel.style.display = 'inline-block';
        this.previousFocus = document.activeElement ?? undefined;
        for (const id in this.options) {
            if (!this.options[id].disabled) {
                this.options[id].focus();
                break;
            }
        }
    }

    public hide(): void {
        this.overlay.style.display = 'none';
        this.panel.style.display = 'none';
        if (this.previousFocus instanceof HTMLElement) {
            this.previousFocus.focus();
        }
    }

    private readonly handleClick = (event: MouseEvent) => {
        if (event.target instanceof HTMLElement) {
            for (const id in this.options) {
                if (this.options[id].contains(event.target)) {
                    this.handler(id);
                }
            }
        }
    }

    private readonly handleKeyup = (event: KeyboardEvent) => {
        //if (event.key === 'Enter' && event.target instanceof HTMLElement) {
        //    for (const id in this.options) {
        //        if (this.options[id].contains(event.target)) {
        //            this.handler(id);
        //        }
        //    }
        //}
        event.preventDefault();
    }

    private readonly handleKeydown = (event: KeyboardEvent) => {
        if (event.target instanceof HTMLElement && this.panel.contains(event.target)) {
            return;
        }
        event.preventDefault();
    }

    public getValue(id: string): string {
        return this.options[id].value;
    }

    public async getChoice(): Promise<string> {
        return new Promise<string>(resolve => {
            this.overlay.style.display = 'block';
            this.panel.style.display = 'inline-block';
            this.buttons.onclick = (event: MouseEvent):void => {
                if (event.target instanceof HTMLElement) {
                    for (const id in this.options) {
                        if (this.options[id].contains(event.target)) {
                            this.overlay.style.display = 'none';
                            this.panel.style.display = 'none';
                            resolve(id);
                        }
                    }
                }
            };
        });
    }
}

export async function alertModal<T>(message: string, handler?: () => Promise<T>): Promise<T|undefined> {
    return new Promise((resolve, reject) => {
        const modal = new Modal({handler: async () => {
            if (handler) {
                try {
                    modal.hide();
                    const result = await handler();
                    resolve(result);
                } catch (err) {
                    modal.hide();
                    reject(err);
                }
            } else {
                modal.hide();
                resolve(undefined);
            }
        }});
        modal.titleHtml(translate('ALERT_TITLE'));
        modal.bodyHtml(message);
        modal.addButtons('navbutton config-safe config-safe-hover config-body-fg-border config-safe-fg-border-focus config-body-fg-shadow-focus', {ok: translate('ALERT_OK')});
        modal.show();
    })
}

export async function confirmModal(message: string, ): Promise<boolean> {
    return new Promise(resolve => {
        const modal = new Modal({handler: async (id: string) => {
            modal.hide();
            switch (id) {
                case 'ok':
                    resolve(true);
                    break;
                case 'cancel':
                default:
                    resolve(false);
                    break;
            }
        }});
        modal.titleHtml(translate('ALERT_TITLE'));
        modal.bodyHtml(message);
        modal.addButtons('navbutton config-ntrl config-ntrl-hover config-body-fg-border config-ntrl-fg-border-focus config-body-fg-shadow-focus', {cancel: translate('ALERT_CANCEL')});
        modal.addButtons('navbutton config-safe config-safe-hover config-body-fg-border config-safe-fg-border-focus config-body-fg-shadow-focus', {ok: translate('ALERT_OK')});
        modal.show();
    });
}

export async function simpleModal({message, neutral = {}, positive = {}, negative = {}}: {message: string, neutral?: {[tag: string]: string}, positive?: {[tag: string]: string}, negative?:  {[tag: string]: string}}): Promise<string> {
    return new Promise(resolve => {
        const modal = new Modal({handler: async (option: string) => {
            modal.hide();
            resolve(option);
        }});
        modal.titleHtml(translate('ALERT_TITLE'));
        modal.bodyHtml(message);
        modal.addButtons('navbutton config-dngr config-dngr-hover config-body-fg-border config-dngr-fg-border-focus config-body-fg-shadow-focus', negative);
        modal.addButtons('navbutton config-ntrl config-ntrl-hover config-body-fg-border config-ntrl-fg-border-focus config-body-fg-shadow-focus', neutral);
        modal.addButtons('navbutton config-safe config-safe-hover config-body-fg-border config-safe-fg-border-focus config-body-fg-shadow-focus', positive);
        modal.show();
    })
}
