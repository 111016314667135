import {Progress} from './utils-progress';
import {translate} from 'utils-lang';
import {safeJsonParse} from 'utils';
//import {version} from '@gen/version';

//let admin = false;
let loginId = "";
let loginPwd = "";

export function setCredentials(args: {loginId: string; loginPwd: string}): void {
    //admin = false;
    loginId = args.loginId;
    loginPwd = args.loginPwd;
}

export function clearCredentials(): void {
    //admin = false;
    loginId = "";
    loginPwd = "";
}

export function urlWithCredentials(url: string): string {
    if (loginId && loginPwd) {
        return `${url}?cid=${encodeURIComponent(loginId)}&pin=${encodeURIComponent(loginPwd)}`; // &p4b=${version}`;
    } else {
        return url;
    }
}

export class HttpError extends Error {
    constructor(public readonly status: number, message: string) {
        super(message);
        this.name = 'HttpError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export class AbortError extends Error {
    constructor() {
        super('HTTP operation terminated');
        this.name = 'AbortError';
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

const postMap: Map<string, {xhr: XMLHttpRequest, data: string}> = new Map();

async function createXhr(url: string, data: string): Promise<XMLHttpRequest|undefined> {
    const post = postMap.get(url);
    if (post !== undefined) {
        return;
        //if (data === post.data) {
        //    return;
        //}
        //post.xhr.abort();
    }
    const xhr = new XMLHttpRequest();
    postMap.set(url, {xhr, data});
    return xhr;
}

function deleteXhr(url: string, xhr: XMLHttpRequest) {
    const post = postMap.get(url);
    if (post !== undefined) {
        if (xhr === post.xhr) {
            postMap.delete(url);
        }
    }
}

export function examCleanup(): void {
    postMap.clear();
}

export async function asyncRequestJson<T>(url: string, obj: T): Promise<{data?: unknown, req_timestamp?: number, rsp_timestamp?: number}> {
    const str = JSON.stringify(obj);
    return new Promise(async (succ, fail) => {
        const xhr = await createXhr(url, str);
        if (xhr !== undefined) {
            try {
                xhr.open('POST', url, true);
                xhr.onerror = (): void => {
                    deleteXhr(url, xhr);
                    fail(new Error(translate('ERROR_NETWORK')));
                };
                xhr.ontimeout = (): void => {
                    deleteXhr(url, xhr);
                    fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
                };
                xhr.onload = () => {
                    const rsp_timestamp = Date.now();
                    deleteXhr(url, xhr);
                    if (xhr.status === 200) {
                        if (xhr.responseText) {
                            const data = safeJsonParse(xhr.responseText);
                            succ({data, req_timestamp, rsp_timestamp});
                        } else {
                            succ({data: null, req_timestamp, rsp_timestamp});
                        }
                    } else {
                        fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                    }
                };
                xhr.onabort = () => {
                    deleteXhr(url, xhr);
                    fail(new AbortError());
                };
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
                xhr.responseType = 'text';
                //xhr.timeout = 300000;
                const req_timestamp = Date.now();
                xhr.send(JSON.stringify(obj));
            } catch (err) {
                deleteXhr(url, xhr);
                fail(err);
            }
        } else {
            console.warn(`Discarding POST to ${url}`);
            succ({});
        }
    });
}

export async function requestJson<T>(url: string, obj: T): Promise<{data: unknown, req_timestamp: number, rsp_timestamp: number}> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onloadstart = () => {
                rsp_timestamp = Date.now();
            }
            xhr.onload = () => {
                if (xhr.status === 200) {
                    if (xhr.responseText) {
                        const data = safeJsonParse(xhr.responseText);
                        succ({data, req_timestamp, rsp_timestamp});
                    } else {
                        succ({data: null, req_timestamp, rsp_timestamp});
                    }
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            xhr.responseType = 'text';
            //xhr.timeout = 30000;
            const req_timestamp = Date.now();
            let rsp_timestamp = req_timestamp;
            xhr.send(JSON.stringify(obj));
        } catch (err) {
            fail(err);
        }
    });
}

export function getHead(url: string): Promise<string> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);

            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(xhr.getResponseHeader('content-length') || '0');
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function getText(url: string): Promise<string> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(xhr.responseText);
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            xhr.responseType = 'text';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export async function getJson(url: string): Promise<unknown> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ(JSON.parse(xhr.responseText));
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            xhr.responseType = 'text';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function httpDelete(url: string): Promise<void> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('DELETE', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = (): void => {
                if (xhr.status === 200) {
                    succ();
                } else {
                    fail(new HttpError(xhr.status, `${xhr.statusText} "${xhr.responseText}"`));
                }
            };
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export interface ProgressUi {
    ui: {
        progressBox: HTMLDivElement;
        titleBox: HTMLDivElement;
        subtextBox: HTMLDivElement;
        title: Text;
        subtext: Text;
        spinner: HTMLDivElement;
        textBox: HTMLDivElement;
        text: Text;
    };
    currentSize?: number;
    totalSize?: number;
}

export async function rangeSupported(url: string): Promise<{ranged:boolean,size:number}> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('HEAD', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onload = (): void => {
                if (xhr.status === 206) {
                    console.log('RANGE', xhr.getResponseHeader('content-range'));
                    console.log('MATCH', xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1]);
                    const ranged = true;
                    const size = parseInt(xhr.getResponseHeader('content-range')?.match(/\/([0-9]*)$/)?.[1] || '0');
                    succ({ranged, size});
                } else if (xhr.status === 200) {
                    const ranged = false;
                    const size = parseInt(xhr.getResponseHeader('content-length') || '0');
                    succ({ranged, size});
                } else {
                    fail(new HttpError(xhr.status, xhr.statusText));
                }
            }
            xhr.setRequestHeader('Range', 'bytes=0-1');
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            xhr.responseType = 'arraybuffer';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}

export function getArrayBuffer(url: string, progress: Progress, start?: number, end?: number): Promise<ArrayBuffer> {
    return new Promise((succ, fail): void => {
        try {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.onerror = (): void => {
                fail(new Error(translate('ERROR_NETWORK')));
            };
            xhr.ontimeout = (): void => {
                console.log('CHARS GOT:', xhr.response.byteLength);
                fail(new Error(translate('ERROR_CONNECTION_TIMEOUT')));
            };
            xhr.onprogress = async (e): Promise<void> => {
                if (e.loaded != null) {
                    await progress.setProgress(e.loaded);
                }
            };
            xhr.onload = (): void => {
                if (xhr.status === 200 || xhr.status === 206) {
                    progress.addOffset(xhr.response.byteLength);
                    console.debug('downloaded exam chunk, start: ', start, ' end: ', start + xhr.response.byteLength);
                    succ(xhr.response);
                } else {
                    fail(new HttpError(xhr.status, xhr.statusText));
                }
            };
            if (start || end) {
                console.debug('range', start, '-', end);
                xhr.setRequestHeader('Range', 'bytes=' + start + '-' + end);
            }
            xhr.setRequestHeader("Cache-Control", "no-cache, no-store, max-age=0");
            xhr.responseType = 'arraybuffer';
            //xhr.timeout = 30000;
            xhr.send(null);
        } catch (err) {
            fail(err);
        }
    });
}
